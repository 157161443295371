import React from "react";

import { WEBSITE_STRINGS } from "../../strings/strings";

import gdprimage from '../../assets/images/gdprImages/gdprimage.png';

interface Props{
    language: string;
  }

function GDPR({language} : Props){

    let languageStrings = null;

    if(language === 'de'){

        languageStrings = WEBSITE_STRINGS.de;
  
      }else{
        languageStrings = WEBSITE_STRINGS.en;
      }

    return(
        <section className='gdpr__page'>    
            <div className='element'>
                <div className='section__header__container'>
                    <h3 className='color__black'>{languageStrings.gdprTitle}</h3>
                </div>
            </div>

            <div className='element element__grid__full'>
                <div className="element__grid__single column">
                    <p>{languageStrings.gdprBeforeOne}</p> <br></br>
                    <p>{languageStrings.gdprBeforeTwo}</p>
                    <p>{languageStrings.gdprBeforeThree}</p>
                    <p>{languageStrings.gdprBeforeFour}</p>
                    <p>{languageStrings.gdprBeforeFive}</p>
                    <p>{languageStrings.gdprBeforeSix}</p>
                    <p>{languageStrings.gdprBeforeSeven}</p>
                    <p>{languageStrings.gdprBeforeEight}</p>
                    <p>{languageStrings.gdprBeforeNine}</p>
                    <p>{languageStrings.gdprBeforeTen}</p>
                    <p>{languageStrings.gdprBeforeEleven}</p>
                </div>
                <div className='element__grid__single text__align__justify'>
                    <p>{languageStrings.gdprPone}</p>
                </div>
                <div className='element__grid__two_columns margin-0'>
                    <div className='element__grid__single column text__align__justify'>
                        <ul className='list'>
                            <li className='list__title'><p>1.</p>{languageStrings.gdprP1.title}</li>
                            <li className='list__item__main'><p>1.1.</p>{languageStrings.gdprP1.p1}</li>
                            <li className='list__item__main'><p>1.2.</p>{languageStrings.gdprP1.p2}</li>
                        </ul>
                    </div>
                    <div className='element__grid__single'>
                        <img className='gdpr__shield' src={gdprimage} alt="GDPR Shield" width="100%" height='368px' />
                    </div>
                </div>
                <div className='element__grid__single column text__align__justify'>
                    <ul className='list'>
                            <li className='list__title'><p>2.</p>{languageStrings.gdprP2.title}</li>
                            <li className='list__item__main'><p>2.1.</p>{languageStrings.gdprP2.p1}</li>
                            <li className='list__item__main'><p>2.2.</p>{languageStrings.gdprP2.p2}</li>
                            <li className='list__item__main'><p>2.3.</p>{languageStrings.gdprP2.p3}</li>
                            <li className='list__item__main'><p>2.4.</p>{languageStrings.gdprP2.p4}</li>
                        </ul>
                        <ul className='list'>
                            <li className='list__title'><p>3.</p>{languageStrings.gdprP3.title}</li>
                            <li className='list__item__main'><p>3.1.</p>{languageStrings.gdprP3.p1}</li>
                            <li className='list__item__main'><p>3.2.</p>{languageStrings.gdprP3.p2}</li>
                        </ul>
                    <ul className='list'>
                        <li className='list__title'><p>4.</p>{languageStrings.gdprP4.title}</li>
                        <li className='list__item__main'><p>4.1.</p>{languageStrings.gdprP4.p1}</li>
                        <li className='list__item__sub'><p>4.1.1.</p>{languageStrings.gdprP4.p1sub.p1}</li>
                        <li className='list__item__sub'><p>4.1.2.</p>{languageStrings.gdprP4.p1sub.p2}</li>
                        <li className='list__item__main'><p>4.2.</p>{languageStrings.gdprP4.p2}</li>
                        <li className='list__item__main'><p>4.3.</p>{languageStrings.gdprP4.p3}</li>
                        <li className='list__item__main'><p>4.4.</p>{languageStrings.gdprP4.p4}</li>
                        <li className='list__item__main'><p>4.5.</p>{languageStrings.gdprP4.p5}</li>
                        <li className='list__item__main'><p>4.6.</p>{languageStrings.gdprP4.p6}</li>
                        <li className='list__item__main'><p>4.7.</p>{languageStrings.gdprP4.p7}</li>
                        <li className='list__item__main'><p>4.8.</p>{languageStrings.gdprP4.p8}</li>
                    </ul>
                    <ul className='list'>
                        <li className='list__title'><p>5.</p>{languageStrings.gdprP5.title}</li>
                        <li className='list__item__main'><p>5.1.</p>{languageStrings.gdprP5.p1}</li>
                        <li className='list__item__main'><p>5.2.</p>{languageStrings.gdprP5.p2}</li>
                        <li className='list__item__main'><p>5.3.</p>{languageStrings.gdprP5.p3}</li>
                        <li className='list__item__main'><p>5.4.</p>{languageStrings.gdprP5.p4}</li>
                    </ul>
                    <ul className='list'>
                        <li className='list__title'><p>6.</p>{languageStrings.gdprP6.title}</li>
                        <li className='list__item__main'><p>6.1.</p>{languageStrings.gdprP6.p1}</li>
                        <li className='list__item__main'><p>6.2.</p>{languageStrings.gdprP6.p2}</li>
                        <li className='list__item__main'><p>6.3.</p>{languageStrings.gdprP6.p3}</li>
                        <li className='list__item__main'><p>6.4.</p>{languageStrings.gdprP6.p4}</li>
                        <li className='list__item__main'><p>6.5.</p>{languageStrings.gdprP6.p5}</li>
                        <li className='list__item__main'><p>6.6.</p>{languageStrings.gdprP6.p6}</li>
                        <li className='list__item__main'><p>6.7.</p>{languageStrings.gdprP6.p7}</li>
                    </ul>
                    <ul className='list'>
                        <li className='list__title'><p>7.</p>{languageStrings.gdprP7.title}</li>
                        <li className='list__item__main'><p>7.1.</p>{languageStrings.gdprP7.p1}</li>
                        <li className='list__item__main'><p>7.2.</p>{languageStrings.gdprP7.p2}</li>
                        <li className='list__item__main'><p>7.3.</p>{languageStrings.gdprP7.p3}</li>
                        <li className='list__item__main'><p>7.4.</p>{languageStrings.gdprP7.p4}</li>
                        <li className='list__item__main'><p>7.5.</p>{languageStrings.gdprP7.p5}</li>
                    </ul>
                    <ul className='list'>
                        <li className='list__title'><p>8.</p>{languageStrings.gdprP8.title}</li>
                        <li className='list__item__main'><p>8.1.</p>{languageStrings.gdprP8.p1}</li>
                        <li className='list__item__main'><p>8.2.</p>{languageStrings.gdprP8.p2}</li>
                        <li className='list__item__main'><p>8.3.</p>{languageStrings.gdprP8.p3}</li>
                        <li className='list__item__main'><p>8.4.</p>{languageStrings.gdprP8.p4}</li>
                        <li className='list__item__main'><p>8.5.</p>{languageStrings.gdprP8.p5}</li>
                        <li className='list__item__main'><p>8.6.</p>{languageStrings.gdprP8.p6}</li>
                        <li className='list__item__main'><p>8.7.</p>{languageStrings.gdprP8.p7}</li>
                        <li className='list__item__main'><p>8.8.</p>{languageStrings.gdprP8.p8}</li>
                        <li className='list__item__main'><p>8.9.</p>{languageStrings.gdprP8.p9}</li>
                    </ul>
                    <ul className='list'>
                        <li className='list__title'><p>9.</p>{languageStrings.gdprP9.title}</li>
                        <li className='list__item__main'><p>9.1.</p>{languageStrings.gdprP9.p1}</li>
                        <li className='list__item__main'><p>9.2.</p>{languageStrings.gdprP9.p2}</li>
                        <li className='list__item__main'><p>9.3.</p>{languageStrings.gdprP9.p3}</li>
                        <li className='list__item__main'><p>9.4.</p>{languageStrings.gdprP9.p4}</li>
                    </ul>
                    <ul className='list'>
                        <li className='list__title'><p>10.</p>{languageStrings.gdprP10.title}</li>
                        <li className='list__item__main'><p>10.1.</p>{languageStrings.gdprP10.p1}</li>
                        <li className='list__item__main'><p>10.2.</p>{languageStrings.gdprP10.p2}</li>
                        <li className='list__item__main'><p>10.3.</p>{languageStrings.gdprP10.p3}</li>
                        <li className='list__item__main'><p>10.4.</p>{languageStrings.gdprP10.p4}</li>
                    </ul>
                    <ul className='list'>
                        <li className='list__title'><p>11.</p>{languageStrings.gdprP11.title}</li>
                        <li className='list__item__main'><p>11.1.</p>{languageStrings.gdprP11.p1}</li>
                        <li className='list__item__main'><p>11.2.</p>{languageStrings.gdprP11.p2}</li>
                        <li className='list__item__main'><p>11.3.</p>{languageStrings.gdprP11.p3}</li>
                        <li className='list__item__main'><p>11.4.</p>{languageStrings.gdprP11.p4}</li>
                        <li className='list__item__main'><p>11.5.</p>{languageStrings.gdprP11.p5}</li>
                    </ul>
                    <ul className='list'>
                        <li className='list__title'><p>12.</p>{languageStrings.gdprP12.title}</li>
                        <li className='list__item__main'><p>12.1.</p>{languageStrings.gdprP12.p1}</li>
                        <li className='list__item__main'><p>12.2.</p>{languageStrings.gdprP12.p2}</li>
                        <li className='list__item__main'><p>12.3.</p>{languageStrings.gdprP12.p3}</li>
                        <li className='list__item__main'><p>12.4.</p>{languageStrings.gdprP12.p4}</li>
                    </ul>
                    <ul className='list'>
                        <li className='list__title'><p>13.</p>{languageStrings.gdprP13.title}</li>
                        <li className='list__item__main'><p>13.1.</p>{languageStrings.gdprP13.p1}</li>
                        <li className='list__item__main'><p>13.2.</p>{languageStrings.gdprP13.p2}</li>
                        <li className='list__item__main'><p>13.3.</p>{languageStrings.gdprP13.p3}</li>
                    </ul>
                    <ul className='list'>
                        <li className='list__title'><p>14.</p>{languageStrings.gdprP14.title}</li>
                        <li className='list__item__main'><p>14.1.</p>{languageStrings.gdprP14.p1}</li>
                        <li className='list__item__main'><p>14.2.</p>{languageStrings.gdprP14.p2}</li>
                        <li className='list__item__main'><p>14.3.</p>{languageStrings.gdprP14.p3}</li>
                        <li className='list__item__main'><p>14.4.</p>{languageStrings.gdprP14.p4}</li>
                        <li className='list__item__main'><p>14.5.</p>{languageStrings.gdprP14.p5}</li>
                        <li className='list__item__main'><p>14.6.</p>{languageStrings.gdprP14.p6}</li>
                    </ul>
                    <ul className='list'>
                        <li className='list__title'><p>15.</p>{languageStrings.gdprP15.title}</li>
                        <li className='list__item__main'><p>15.1.</p>{languageStrings.gdprP15.p1}</li>
                        <li className='list__item__main'><p>15.2.</p>{languageStrings.gdprP15.p2}</li>
                    </ul>
                    <ul className='list'>
                        <li className='list__title'><p>16.</p>{languageStrings.gdprP16.title}</li>
                        <li className='list__item__main'><p>16.1.</p>{languageStrings.gdprP16.p1}</li>
                        <li className='list__item__main'><p>16.2.</p>{languageStrings.gdprP16.p2}</li>
                    </ul>
                </div>
            </div>

        </section>
    );

}

export default GDPR;