import React from "react";
import { WEBSITE_STRINGS } from "../../strings/strings";

import about_image from '../../assets/images/aboutImages/aboutImage.png'; 
import gallery_image_1 from '../../assets/images/aboutImages/gallery/img1.png'; 
import gallery_image_2 from '../../assets/images/aboutImages/gallery/img2.png'; 
import gallery_image_3 from '../../assets/images/aboutImages/gallery/img3.png'; 
import gallery_image_4 from '../../assets/images/aboutImages/gallery/img4.png'; 

interface Props{
    language: string;
  }

function About({language} : Props){

    let languageStrings = null;

    if(language === 'de'){

      languageStrings = WEBSITE_STRINGS.de;

    }else{
      languageStrings = WEBSITE_STRINGS.en;
    }

    return(
        <>
            <section className='about__page'>

                <div className='element'>
                    <div className='section__header__container'>
                        <h3 className='color__black'>{languageStrings.aboutTitle}</h3>
                    </div>
                </div>

                <div className='element'>

                    <div className="element__grid__two_columns">
                        <div className='element__grid__single flex__column padding__right__2 text__align__justify'>
                            <p>{languageStrings.aboutParagraph1}</p>
                            <p>{languageStrings.aboutParagraph2}</p>
                            <p>{languageStrings.aboutParagraph3}</p>
                        </div>
                        <div className='element__grid__single flex__column flex__column__center about__image'>
                            <img src={about_image} alt='About' />
                        </div>
                    </div>

                </div>

            </section>

            {/*<section className="mission__vision__section about__section__1">
                <div className="overlay__linear"></div>

                <div className='element padding__top__bottom'>
                    <div className="element__grid__two_columns margin-0">
                        <div className='element__grid__single flex__column border__right'>
                            <div className='section__header__container'>
                                <h3 className='color__white'>{languageStrings.aboutMissionTitle}</h3>
                            </div>
                            <div className="paragraph color__white">
                                <p>{languageStrings.aboutMissionText}</p>
                            </div>
                        </div>
                        <div className='element__grid__single flex__column border__left'>
                            <div className='section__header__container'>
                                <h3 className='color__white'>{languageStrings.aboutVisionTitle}</h3>
                            </div>
                            <div className="paragraph color__white">
                                <p>{languageStrings.aboutVisionText}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>*/}

            <section className='gallery__section'>
                <div className='element'>
                    <div className='section__header__container'>
                        <h3 className='color__black'>{languageStrings.aboutGalleryTitle}</h3>
                    </div>
                </div>
                <div className='element gallery__element'>
                    <div className='gallery__image'><img src={gallery_image_1} alt='Gallery1' /></div>
                    <div className='gallery__image'><img src={gallery_image_2} alt='Gallery2' /></div>
                    <div className='gallery__image'><img src={gallery_image_3} alt='Gallery3' /></div>
                    <div className='gallery__image'><img src={gallery_image_4} alt='Gallery4' /></div>
                </div>
            </section>
        </>
    );

}

export default About;