import { makeAutoObservable } from "mobx";

export default class CommonStore {
    language: string | null = null;
    appLoaded = false;

    constructor() {
        makeAutoObservable(this);

        if(window.localStorage.getItem('lang') === null){
            this.setLanguage('de');
            window.localStorage.setItem('lang', 'de');
        }
        else{
            this.setLanguage(window.localStorage.getItem('lang')!);
        }
    }

    setLanguage = (language: string) => {
        this.language = language;
    }

    setAppLoaded = () => {
        this.appLoaded = true;
    }
}