import React from "react";

import { WEBSITE_STRINGS } from "../../strings/strings";

import serviceImg1 from '../../assets/images/servicesPageImages/serviceImg1.png';
import serviceImg2 from '../../assets/images/servicesPageImages/serviceImg2.png';
import serviceImg3 from '../../assets/images/servicesPageImages/serviceImg3.png';
import serviceImg4 from '../../assets/images/servicesPageImages/serviceImg4.png';
import serviceImg5 from '../../assets/images/servicesPageImages/serviceImg5.png';

import { useHistory } from "react-router";

interface Props{
    language: string;
  }

function Services({language} : Props){

    const history = useHistory();

    let languageStrings = null;

    if(language === 'de'){

      languageStrings = WEBSITE_STRINGS.de;

    }else{
      languageStrings = WEBSITE_STRINGS.en;
    }

    return(
        <>
            <section className='services__page'>
                <div className='element'>
                    <div className='section__header__container'>
                        <h3 className='color__black'>{languageStrings.servicesTitle}</h3>
                    </div>
                </div>

                <div className='element element__grid__full'>
                    <div className='element__grid__single text__align__justify'>
                        <p>{languageStrings.servicesSubTitle}</p>
                    </div>
                </div>

                <div className='element element__grid'>
                    <div className='element__grid__single flex__column text__center align__center service' id='service1'>
                        <div className='text'>
                            <h5>{languageStrings.serviceGrid1Title}</h5>
                            <p>{languageStrings.serviceGrid1Text}</p>
                        </div>
                        <div className='service__img'>
                            <img src={serviceImg1} alt='serviceImg1' />
                        </div>
                    </div>
                    <div className='element__grid__single flex__column text__center align__center service' id='service2'>
                        <div className='text'>
                            <h5>{languageStrings.serviceGrid2Title}</h5>
                            <p>{languageStrings.serviceGrid2Text}</p>
                        </div>
                        <div className='service__img'>
                            <img src={serviceImg2} alt='serviceImg2' />
                        </div>
                    </div>
                    <div className='element__grid__single flex__column text__center align__center service' id='service3'>
                        <div className='text'>
                            <h5>{languageStrings.serviceGrid3Title}</h5>
                            <p>{languageStrings.serviceGrid3Text}</p>
                        </div>
                        <div className='service__img'>
                            <img src={serviceImg3} alt='serviceImg3' />
                        </div>
                    </div>
                    <div className='element__grid__single flex__column text__center align__center service' id='service4'>
                        <div className='text'>
                            <h5>{languageStrings.serviceGrid4Title}</h5>
                            <p>{languageStrings.serviceGrid4Text}</p>
                        </div>
                        <div className='service__img'>
                            <img src={serviceImg4} alt='serviceImg4' />
                        </div>
                    </div>
                    <div className='element__grid__single flex__column text__center align__center service' id='service5'>
                        <div className='text'>
                            <h5>{languageStrings.serviceGrid5Title}</h5>
                            <p>{languageStrings.serviceGrid5Text}</p>
                        </div>
                        <div className='service__img'>
                            <img src={serviceImg5} alt='serviceImg5' />
                        </div>
                    </div>
                </div>

            </section>
        </>

    );

}

export default Services;