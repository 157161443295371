import React from "react";
import { useHistory } from "react-router";
import workerImage from '../../assets/images/male_constructor_worker.png';

import Image1 from '../../assets/images/servicesImages/Image1.png';
import Image2 from '../../assets/images/servicesImages/Image2.png';
import Image3 from '../../assets/images/servicesImages/Image3.png';
import Image4 from '../../assets/images/servicesImages/Image4.png';
import Image5 from '../../assets/images/servicesImages/Image5.png';

import { WEBSITE_STRINGS } from "../../strings/strings";

interface Props{
    language: string;
  }

function Home({language} : Props){

    const history = useHistory();

    let languageStrings = null;

    if(language === 'de'){

      languageStrings = WEBSITE_STRINGS.de;

    }else{
      languageStrings = WEBSITE_STRINGS.en;
    }

    return(
        <>
            <section className='home__section home_section_1'>
                <div className='element'>
                    <div className='home__header_container'>
                        <div className='home__header color__white'>
                            <h1>{languageStrings.workforce4rail}</h1>
                        </div>
                        <div className='home__subheader color__white'>
                            <h5>{languageStrings.homesubtitle}</h5>
                        </div>

                        <div className='home__subheader home__button__container'>
                            <button className='button__red' onClick={() => {history.push('/about')}}>
                                <span>{languageStrings.findoutmore}</span>
                            </button>
                        </div>
                    </div>

                </div>

                <div className='home__cover__subsection color__white'>
                    <div className='white__space'></div>
                    <div className='home__image__button'><span>{languageStrings.homesubheader1}</span></div>
                    <div className='home__image__button'><span>{languageStrings.homesubheader2}</span></div>
                    <div className='home__image__button'><span>{languageStrings.homesubheader3}</span></div>
                    <div className='white__space'></div>
                </div>
            </section>

            <section className='services__section services__section__1'>
                <div className='element'>
                    <div className='section__header__container'>
                        <h3 className='color__black'>{languageStrings.services}</h3>
                    </div>
                </div>
                <div className='element element__grid'>
                    <div className='element__grid__single mobile__text__center mobile__align__center'>
                        <p>{languageStrings.homeservicesText}</p>
                    </div>
                    <div className='element__grid__single mobile__text__center mobile__align__center'>
                        <div className='image'>
                            <img src={Image1} alt='Cabels' />
                        </div>
                        <div className='text'>
                            <h5>{languageStrings.service1Title}</h5>
                            {/*<p>{languageStrings.service1Text}</p>*/}
                        </div>
                    </div>
                    <div className='element__grid__single mobile__text__center mobile__align__center'>
                        <div className='image'>
                            <img src={Image2} alt='Railroad' />
                        </div>
                        <div className='text'>
                            <h5>{languageStrings.service2Title}</h5>
                            {/*<p>{languageStrings.service2Text}</p>*/}
                        </div>
                    </div>
                    <div className='element__grid__single mobile__text__center mobile__align__center'>
                        <div className='image'>
                            <img src={Image3} alt='Welding' />
                        </div>
                        <div className='text'>
                            <h5>{languageStrings.service3Title}</h5>
                            {/*<p>{languageStrings.service3Text}</p>*/}
                        </div>
                    </div>
                    <div className='element__grid__single mobile__text__center mobile__align__center'>
                        <div className='image'>
                            <img src={Image4} alt='High power' />
                        </div>
                        <div className='text'>
                            <h5>{languageStrings.service4Title}</h5>
                            {/*<p>{languageStrings.service4Text}</p>*/}
                        </div>
                    </div>
                    <div className='element__grid__single mobile__text__center mobile__align__center'>
                        <div className='image'>
                            <img src={Image5} alt='Machine renting' />
                        </div>
                        <div className='text'>
                            <h5>{languageStrings.service5Title}</h5>
                            {/*<p>{languageStrings.service5Text}</p>*/}
                        </div>
                    </div>
                </div>
            </section>

            <section className="about__section about__section__1">
                <div className="overlay"></div>

                <div className='element'>
                    <div className='section__header__container'>
                        <h3 className='color__white'>{languageStrings.aboutus}</h3>
                    </div>
                </div>

                <div className="element about__us__paragraph">
                    <div className="paragraph color__white">
                        <p>{languageStrings.homeAboutUsText1}</p>
                        <p>{languageStrings.homeAboutUsText2}</p>
                    </div>
                    <div className="image">
                        <img src={workerImage} alt="Worker" />
                    </div>
                </div>
            </section>

            <section className="more__section more__section__1">
                <div className='element'>
                    <div className='section__header__container'>
                        <h3 className='color__black'>{languageStrings.needMoreInfoTitle}</h3>
                    </div>
                </div>

                <div className='element element__grid__full'>
                    <div className='element__grid__single'>
                        <p>{languageStrings.needMoreInfoText1}</p>
                    </div>

                    <div className='element__grid__single'>
                        <button onClick={() => history.push('/contact')} className='button__red'><span>{languageStrings.getInTouch}</span></button>
                    </div>
                </div>
            </section>
        </>
    );

}

export default Home;