import React from "react";

import { WEBSITE_STRINGS } from "../../strings/strings";

import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import {Icon} from 'leaflet';
import 'leaflet/dist/leaflet.css';

import email_img from '../../assets/images/contactImages/email.png';
import location_img from '../../assets/images/contactImages/location_on.png';
import phone_img from '../../assets/images/contactImages/phone_enabled.png';

interface Props{
    language: string;
  }

function Contact({language} : Props){

    let languageStrings = null;

    if(language === 'de'){

      languageStrings = WEBSITE_STRINGS.de;

    }else{
      languageStrings = WEBSITE_STRINGS.en;
    }


    return(
        <section className='contact__page'>    
            <div className='element'>
                <div className='section__header__container'>
                    <h3 className='color__black'>{languageStrings.contact}</h3>
                </div>
            </div>


            <div className='element'>

                <div className='element__grid__two_columns'>
                    <div className='element__grid__single flex__column border__right'>
                        <p className='bold'>
                            {languageStrings.contactSubTitle}
                        </p>

                        <div className='contact__item__container'>
                            <div className='contact__item__image'>
                                <img src={location_img} alt='Location Contact' />
                            </div>
                            <div className='contact__item__text'>
                                <p>{languageStrings.contactStreet1} <br />
                                {languageStrings.contactStreet2} <br />
                                {languageStrings.contactStreet3}</p>
                            </div>
                        </div>

                        <div className='contact__item__container'>
                            <div className='contact__item__image'>
                                <img src={phone_img} alt='Phone Contact' />
                            </div>
                            <div className='contact__item__text'>
                                <p><a href='tel:+4917680575508'>+49 176 80575508</a></p>
                            </div>
                        </div>

                        <div className='contact__item__container'>
                            <div className='contact__item__image'>
                                <img src={email_img} alt='Email Contact' />
                            </div>
                            <div className='contact__item__text'>
                                <p><a href='mailto:info@workforce4rail.com'>info@workforce4rail.com</a></p>
                            </div>
                        </div>
                    </div>

                    <div className='element__grid__single flex__column border__left'>

                        <div className='input__container'>
                            <input type='text' placeholder={languageStrings.contactNamePlaceholder} />
                        </div>

                        <div className='input__container'>
                            <input type='email' placeholder={languageStrings.contactEmailPlaceholder} />
                        </div>

                        <div className='input__container'>
                            <textarea placeholder={languageStrings.contactTextMessagePlaceholder} />
                        </div>

                        <div className='input__container input__container__right'>
                            <button className='button__red'><span>{languageStrings.contactButton}</span></button>
                        </div>

                    </div>
                </div>

                <MapContainer center={[53.65039823463343, 9.776764362676644]} zoom={13} scrollWheelZoom={false}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker position={[53.65039823463343, 9.776764362676644]} icon={new Icon({iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41]})}>
                    </Marker>
                </MapContainer>

            </div>

        </section>
    );

}

export default Contact;