import React from "react";
import { Link, useLocation } from "react-router-dom";
import { observer } from 'mobx-react-lite';

import logo from '../assets/images/wf4r_logo.png';
import de_logo from '../assets/images/languageImages/DE.png';
import en_logo from '../assets/images/languageImages/GB.png';
import { WEBSITE_STRINGS } from '../strings/strings';
import { useStore } from "../stores/store";

interface Props{
  language: string;
}

function NavBar({language} : Props) {

    const location = useLocation();
    let languageStrings = null;

    const {commonStore} = useStore();

    if(language === 'de'){

      languageStrings = WEBSITE_STRINGS.de;

    }else{
      languageStrings = WEBSITE_STRINGS.en;
    }

    const showNav = () => {
      const navList = document.querySelector('.nav__list');
      const hamburger = document.querySelector('.hamburger');
      if(!navList?.classList.contains('nav__list__show')){
        navList?.classList.add('nav__list__show');
        hamburger?.classList.add('hamburger__open');
      }
      else{
        navList?.classList.remove('nav__list__show');
        hamburger?.classList.remove('hamburger__open');
      }
      
    }

    return (
      <header>
        <div className='navigation'>
          <a className='header-logo' href='/'>
            <img  alt='Logo' src={logo} width='125px' />
          </a>
            <button className='nav__button' onClick={showNav} >
              <div className='nav__toggle'>
                <span className='hamburger'></span>
              </div>
            </button>

          <nav>
            <ul className='nav__list'>
              <li className={location.pathname === '/' ?            'nav__item nav__item_active' : 'nav__item'}> <Link to='/'>{languageStrings.home}</Link> </li>
              <li className={location.pathname === '/services' ?    'nav__item nav__item_active' : 'nav__item'}> <Link to='/services'>{languageStrings.services}</Link> </li>
              <li className={location.pathname === '/about' ?       'nav__item nav__item_active' : 'nav__item'}> <Link to='/about'>{languageStrings.aboutus}</Link> </li>
              <li className={location.pathname === '/gdpr' ?        'nav__item nav__item_active' : 'nav__item'}> <Link to='/gdpr'>{languageStrings.gdpr}</Link> </li>
              <li className={location.pathname === '/contact' ?     'nav__item nav__item_active' : 'nav__item'}> <Link to='/contact'>{languageStrings.contact}</Link> </li>
              <li className='nav__item'> {language === 'de' ? 
                <span className='nav__item__language' onClick={() => commonStore.setLanguage('en')}> <img src={de_logo} alt={"de_logo"} height='20px' /> </span> : 
                <span className='nav__item__language' onClick={() => commonStore.setLanguage('de')}> <img src={en_logo} alt={"en_logo"} height='20px' /> </span> }
              </li>
            </ul>
          </nav>
        </div>  
      </header>
    );
  }

export default observer(NavBar);