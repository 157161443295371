import React, { useEffect } from 'react';
import NavBar from './NavBar';
import Container from '../defaults/Container';
import { Route } from 'react-router';
import Home from '../features/webpage/Home';
import Services from '../features/webpage/Services';
import About from '../features/webpage/About';
import GDPR from '../features/webpage/GDPR';
import Contact from '../features/webpage/Contact';
import './App.scss';
import Footer from './Footer';
import { observer } from 'mobx-react-lite';
import { useStore } from "../stores/store";

function App() {

  const {commonStore } = useStore();

  useEffect(()=>{
    if(!commonStore.language){
      commonStore.setLanguage('de');
    }
  }, [commonStore]);

  const language = commonStore.language;

  return (
    <>
      <NavBar language={language!}/>
      <Container>
        <Route exact path='/'> <Home language={language!}/> </Route>
        <Route exact path='/services'> <Services language={language!}/> </Route>
        <Route exact path='/about'> <About language={language!}/> </Route>
        <Route exact path='/gdpr'> <GDPR language={language!}/> </Route>
        <Route exact path='/contact'> <Contact language={language!}/> </Route>
      </Container>
      <Footer language={language!}/>
    </>
  );
}

export default observer(App);
