import { observer } from "mobx-react-lite";
import React from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import logo from '../assets/images/wf4r_logo.png';
import { WEBSITE_STRINGS } from '../strings/strings';

interface Props{
    language: string;
  }

function Footer({language} : Props){

    const d = new Date();
    let year = d.getFullYear(); 

    const location = useLocation();

    let languageStrings = null;
    if(language === 'de'){

        languageStrings = WEBSITE_STRINGS.de;
  
      }else{
        languageStrings = WEBSITE_STRINGS.en;
      }
  

    return (
        <footer>
            <div className='upper__footer'>
                <div className="image">
                    <img src={logo} alt="footer_logo" />
                </div>
                <div className="links">
                    <ul className='footer__nav__list'>
                        <li className={location.pathname === '/' ?            'nav__item nav__item_active' : 'nav__item'}><Link to='/'>{languageStrings.home}</Link></li>
                        <li className={location.pathname === '/services' ?    'nav__item nav__item_active' : 'nav__item'}><Link to='/services'>{languageStrings.services}</Link></li>
                        <li className={location.pathname === '/about' ?       'nav__item nav__item_active' : 'nav__item'}><Link to='/about'>{languageStrings.aboutus}</Link></li>
                        <li className={location.pathname === '/gdpr' ?        'nav__item nav__item_active' : 'nav__item'}><Link to='/gdpr'>{languageStrings.gdpr}</Link></li>
                        <li className={location.pathname === '/contact' ?     'nav__item nav__item_active' : 'nav__item'}><Link to='/contact'>{languageStrings.contact}</Link></li>
                    </ul>
                </div>
            </div>
            <div className="lower__footer">
                <div className="copyright color__white">
                    <p>© {year} <a href="https://www.evision.ba" target='_blank' rel='noreferrer'>eVision</a>. {languageStrings.allRightsReserved}</p>
                </div>
            </div>
        </footer>
    );
}

export default observer(Footer);